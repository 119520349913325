@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-text {
  font-family: "Permanent Marker", cursive;
  font-size: 55px;
}

h1 {
  font-family: "Bungee Spice", cursive;
}

h2 {
  font-family: "Permanent Marker", cursive;
  font-size: 70px;
  font-weight: bold;
}

p {
  font-family: "Podkova", cursive;
}

.lgcontainer {
  width: 100%;
}

.video-wrapper {
  padding-top: 50px;
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
}

.video-container {
  width: 80%;
  padding-top: 56.25%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.divider {
  width: 100%;
  color: gray-500;
  padding-bottom: 100px;
}

.banner1 {
  padding-bottom: 100px;
  height: 200px;
  width: 900px;
  margin: 10px auto;
  margin-bottom: 200px;
}

.banner2 {
  padding-top: 400px;
  padding-bottom: 300px;
  height: 100px;
  width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.route-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.App-link {
  color: #61dafb;
}

.infoComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.infoHeader {
  font-size: 2rem;
  margin-bottom: 20px;
}

.infoButton {
  padding: 10px 20px;
  font-size: 1rem;
}

.aboutInfo {
  font-family: "Pangolin", cursive;
  font-size: 3rem;
  padding-top: 50px;
}

.aboutInfoTitle {
  padding-top: 50px;
  font-family: "Permanent Marker", cursive;
  font-size: 4rem;
}

.aboutStats {
  font-family: "Pangolin", cursive;
  font-size: 3rem;
  padding-top: 15px;
  margin-bottom: 10px;
}

.blockRewardsBox {
  font-family: "Pangolin", cursive;
  font-size: 3rem;
  color: #32cd32;
  font-style: italic;
  padding-top: 64px;
  margin-bottom: 20px;
}

.statsBox {
  margin-bottom: 75px;
}

.titleBanner {
  font-family: "Pangolin", cursive;
  font-style: italic;
  color: #32cd32;
  font-size: 3rem;
  padding-top: 60px;
}

.aboutBottom {
  font-family: "Pangolin", cursive;
  color: #32cd32;
  font-style: italic;
  font-size: 3rem;
  padding-top: 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
